<template>
  <main class="production">
    <section class="container aboutUs__banner">
      <img width="100%" src="/img/banner-aboutUs.jpg" alt="banner-aboutUs" title="banner-aboutUs"/>
    </section>
    <div class="container">
      <section class="production">
        <section>
          <div
            class="production__text"
            :class="{ 'mt-12': $vuetify.breakpoint.smAndDown }"
          >
            <p class="mx-auto">
              There are two production approaches we use making felt shoes. The
              traditional method is wet-fetling - handmade process of making
              seamless shoes. The improvements in technology also allowed us to
              optimize the process by sewing shoes which made the shoes lighter
              and flexible to customize the design.
            </p>
          </div>

          <v-tabs
            hide-slider
            v-model="tab"
            class="mb-4"
            :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }"
          >
            <v-tab
              style="height: fit-content"
              :class="{ 'px-12': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
            >
              Felt shoes
            </v-tab>
            <v-tab
              style="height: fit-content"
              :class="{ 'px-12': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
            >
              Interior
            </v-tab>
            <v-tab
              style="height: fit-content"
              :class="{ 'px-12': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
            >
              Accessories
            </v-tab>
          </v-tabs>

          <div class="production__image">
            <img src="" alt="production__image" title="production__image" />
          </div>
        </section>

        <section>
          <div class="production__gallery-title">
            <h2>Wet felting technology</h2>
            <p class="mx-auto">
              We clean wool by removing all the dirt and raw hair. Wool fibers
              are then untangled, washed with hot water and dyed.
            </p>
          </div>
          <ul class="production__gallery pa-0">
            <li>
              <div class="production__gallery-img1">
                <img src="" alt="production__gallery-img1" title="production__gallery-img1"/>
              </div>
              <div class="production__gallery-img2">
                <img src="" alt="production__gallery-img2" title="production__gallery-img2" />
              </div>
            </li>
            <li>
              <div class="production__gallery-img2">
                <img src="" alt="production__gallery-img2" title="production__gallery-img2" />
              </div>
              <div class="production__gallery-img1">
                <img src="" alt="production__gallery-img1" title="production__gallery-img1" />
              </div>
            </li>
            <li>
              <div class="production__gallery-img1">
                <img src="" alt="production__gallery-img1" title="production__gallery-img1" />
              </div>
              <div class="production__gallery-img1">
                <img src="" alt="production__gallery-img1" title="production__gallery-img1" />
              </div>
              <div class="production__gallery-img1">
                <img src="" alt="production__gallery-img1" title="production__gallery-img1" />
              </div>
            </li>
          </ul>
        </section>

        <section>
          <h2 class="production__card-title">Lorem ipsum</h2>
          <ul class="production__card">
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Sheep shearing</h3>
              <p>
                Sheep shearing takes place once a year in spring. Hair shaving
                tool is run along a sheep’s skin without cause any pain.
              </p>
            </li>
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Cleaning wool</h3>
              <p>
                We clean wool by removing all the dirt and raw hair. Wool fibers
                are then untangled, washed with hot water and dyed.
              </p>
            </li>
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Wet felting</h3>
              <p>
                We make felt by adding hot water and soap to the cleaned wool.
                By rolling felt around shoe molds we regulate the shape.
              </p>
            </li>
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Drying</h3>
              <p>
                After achieving the necessary shape, density and size, we dry
                them on shoe molds. Slippers are then shaved on the surface to
                remove the loose fibers.
              </p>
            </li>
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Outsoles</h3>
              <p>
                After being dried, we glue and sew outsoles around the edges.
                Brand labels and additional bands are also sewn to the shoes at
                this stage.
              </p>
            </li>
            <li
              class="production__card-item"
              :class="{ 'mb-12': $vuetify.breakpoint.smAndDown }"
            >
              <h3>Wastewater</h3>
              <p>
                The water used for the felt-making process is then cleaned with
                the mechanical filtering system and provided for planting at the
                local farms
              </p>
            </li>
          </ul>
          <div class="production__image">
            <img src="" alt="" />
          </div>
        </section>

        <section>
          <div class="production__gallery-title">
            <h2>Wet felting technology</h2>
            <p class="mx-auto">
              We clean wool by removing all the dirt and raw hair. Wool fibers
              are then untangled, washed with hot water and dyed.
            </p>
          </div>
          <div class="production__image">
            <img src="" alt="" />
          </div>
          <v-container fluid class="pa-0 production__card">
            <v-row no-gutters>
              <v-col
                class="production__card-item production__card-item1"
                cols="12"
                :class="{ 'mt-9': $vuetify.breakpoint.smAndDown }"
                md="4"
              >
                <h3>Preparing felt sheets</h3>
                <p>
                  We first prepare felt sheets by wet-felting wool and
                  regulating to achieve the necessary thickness and density.
                </p>
              </v-col>
              <v-col
                class="production__card-item production__card-item1"
                cols="12"
                :class="{ 'mt-9': $vuetify.breakpoint.smAndDown }"
                md="4"
              >
                <h3>Cutting & sewing</h3>
                <p>
                  Felt sheets are then cut into shoe patterns designed for each
                  model. The cut-outs are then sewn together on a machine.
                </p>
              </v-col>
              <v-col
                class="production__card-item production__card-item1"
                cols="12"
                :class="{ 'mt-9': $vuetify.breakpoint.smAndDown }"
                md="4"
              >
                <h3>Forming</h3>
                <p>
                  The pre-forms are then worn on shoe molds, ironed on the
                  outside and corrected to achieve the necessary shape.
                </p>
              </v-col>
            </v-row>
          </v-container>
          <div class="production__image">
            <img src="" alt="" />
          </div>
        </section>

        <section class="quality">
          <div class="mb-12">
            <h2 class="quality__title">Quality Standards</h2>
            <p class="quality__text">
              Due to the handmade production processes, some deviation from the
              standard parameters is inescapable. However, for each production
              process, quality indications are defined and checked during each
              stage of production. Such verification is complied with the
              international quality control system.
            </p>
          </div>
          <v-expansion-panels accordion flat class="aboutUs__accordion">
            <v-expansion-panel class="aboutUs__accordion-item">
              <v-expansion-panel-header hide-actions class="accordion-btn pl-0">
                <span class="accordion-icon"></span>
                <h2>Shape</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <p>
                  Due to the handmade production processes, some deviation from
                  the standard parameters is inescapable. However, for each
                  production process, quality indications are defined and
                  checked during each stage of production. Such verification is
                  complied with the international quality control system.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="aboutUs__accordion-item">
              <v-expansion-panel-header hide-actions class="accordion-btn pl-0">
                <span class="accordion-icon"></span>
                <h2>Density</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <p>
                  Due to the handmade production processes, some deviation from
                  the standard parameters is inescapable. However, for each
                  production process, quality indications are defined and
                  checked during each stage of production. Such verification is
                  complied with the international quality control system.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="aboutUs__accordion-item">
              <v-expansion-panel-header hide-actions class="accordion-btn pl-0">
                <span class="accordion-icon"></span>
                <h2>Color</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <p>
                  Due to the handmade production processes, some deviation from
                  the standard parameters is inescapable. However, for each
                  production process, quality indications are defined and
                  checked during each stage of production. Such verification is
                  complied with the international quality control system.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="aboutUs__accordion-item">
              <v-expansion-panel-header hide-actions class="accordion-btn pl-0">
                <span class="accordion-icon"></span>
                <h2>Gluing</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <p>
                  Due to the handmade production processes, some deviation from
                  the standard parameters is inescapable. However, for each
                  production process, quality indications are defined and
                  checked during each stage of production. Such verification is
                  complied with the international quality control system.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </section>

        <v-divider color="#F0F0F0"></v-divider>

        <section class="quality mt-8">
          <div class="production__comment">
            <span class="comment-icon"></span>
            <h3 class="production__comment-title">
              Compost your worn out felt shoes
            </h3>
            <p class="production__comment-text mx-auto">
              Learn about three ways how you can give the soil back to earth
            </p>
          </div>
        </section>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style lang="scss">
.production {
  .v-slide-group__content {
    flex: unset;
    margin: 0 auto;
  }
  .v-tabs-bar {
    height: fit-content;
  }
}
</style>
<style lang="scss" scoped>
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
.v-tab {
  text-transform: none;
  &:not(:last-child) {
    border-right: 1px solid #e6e6e6;
  }
  &.v-tab {
    color: #9a9a9a !important;
    font-size: 16px;
    font-weight: 500;
  }
  &.v-tab--active {
    color: #423f3d !important;
  }
}
</style>
